<template>
  <div class="main">
    <div class="calendar-container">
      <router-link :to="{name : 'Calendar'}" class="btn"><i class="fa-solid fa-calendar btn-icon"></i>{{ $t("education.calendar")}}</router-link>
    </div>
    <blog :cat="[1,2]"></blog>
  </div>
</template>

<script>
import Blog from "./../components/Blog.vue";

export default {
  name: "News",
  components : { Blog }
}
</script>

<style scoped lang="scss">

.main {
  width:$blog;
  max-width: $max;
  margin:40px auto;
}

.calendar-container {
  width:100%;
  display: flex;
  justify-content: center;
}
</style>